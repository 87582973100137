.carousel-container {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: black;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 25rem;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.left-arrow, .right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #ffffff57;
    /* border-radius: 24px; */
    /* background-color: white; */
    /* border: 1px solid #ddd; */
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

#back-button{
    width: 100%;
    font-weight: bold;
    height: 10vh;
    background-color: black;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* a:visited { color: rgb(255, 255, 0);} */
a:link{
    text-decoration: none; 
    font-weight: bold;
    font-size: 1.5rem;
}

#back-button-link{
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    border: 1px solid  white;
    color: white;
}