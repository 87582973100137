p{
    margin: 0px;
}
#start-page{
    width: 100%;
    height: 100%;
}
#start-header{
    height: 80%;
    padding: 5%;

}
#start-header-title{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 3rem;
}
#start-header-logo{
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#start-header-logo-icon{
    width: 5rem;
}
#start-footer{
    height: 10%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
    