#myMapWrapper{
    width: 100%;
    height: calc(100% - 30px);
}
#myMap{
    width: 100%;
    height: 100%;
}
#geolocation-tag{
    position: absolute;
    top: 2.5rem;
    right: 0.5rem;
    z-index: 1001;
}
#geolocation-icon{
    width: 1.5rem;
}