#toilet-summary{
    position: absolute;
    bottom: 0%;
    /* left: 15%; */
    z-index: 102;
    background-color: #ffffff;
    width: calc(100% - 40px);
    height: 35%;
    display: none;
    padding: 20px 20px 20px 20px;
    border-radius: 35px 35px 0px 0px;
    border-top: 1px solid #e0e0e0;

}

#toilet-card{
    width:100%;
    height: 100%;
    overflow-y:auto;

    /* padding: 20px 0px 0px 0px; */
}

#toilet-card::-webkit-scrollbar {
    display: none;
}
#toilet-card-node{
    margin: 10px;
    display: flex;
    align-items: center;
    padding: 3px;
    border: 1px solid grey;
    border-radius: 10px;
    color: grey;
}
#toilet-card-node-img{
    width: 1rem;
    margin-right: 5px;
}
#toilet-title{
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-around; */
    margin-bottom: 0.5rem;
}

#toilet-title-name{
    font-size: 1.2rem;
    font-weight: 900;
}

#toilet-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.95rem;
    flex-direction: column-reverse;
}
#toilet-content-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}
#toilet-content-address{
    /* margin: 10px 0px; */
    display: flex;
    flex-direction: column;
}

#toilet-card-label{
    color: grey;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 10px 0px;
    width: 100%;
}
#toilet-title-label{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
#toilet-tag-name{
    border: 2px solid #15d415;
    width: 20%;
    font-weight: 600;
    text-align: center;
    background-color: #15d415;
    font-size: 0.8rem;
    border-radius: 10px;
    margin-left: 1rem;
    color: white;
}
#toilet-tag-navi{
    border: 2px solid #fde129;
    width: 20%;
    font-weight: 600;
    text-align: center;
    background-color: #fde129;
    font-size: 0.8rem;
    border-radius: 10px; 
    margin-left: 1rem;
    color: black;

}
#toilet-photo{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    text-decoration: none;
}

#toilet-photo-name{
    border: 2px solid #0d62ff;
    font-weight: 600;
    text-align: center;
    background-color: #0d62ff;
    font-size: 0.6rem;
    border-radius: 10px;
    color: white;
    width: 3rem;

}


#search-icon{
    width: 15%;
    position: absolute;
    top: 15%;
}
/* .krqTkm{
    height: 100%!important;
} */